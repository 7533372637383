<template>
  <v-row no-gutters>
    <v-col>
      <div v-if="status" id="tracking-list">
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar
            :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'"
            :flat="!$vuetify.breakpoint.smAndDown"
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons.mdiOrderBoolAscending }}
              </v-icon>
              Açık Siparişler
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <download-excel
              v-if="!$vuetify.breakpoint.smAndDown"
              :data="json_data"
              :fields="json_fields"
              name="ACIK_SIPARISLER__ANLAS.xls"
            >
              <v-btn plain text small>
                <v-icon left>
                  {{ icons.mdiDownload }}
                </v-icon>
                İndir
              </v-btn>
            </download-excel>
          </v-toolbar>

          <!-- table -->
          <v-data-table
            :headers="tableColumns"
            :items="trackingListTable"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :loading="loading"
          >
            <!-- Text -->

            <template #[`item.VALIDFROM`]="{ item }">
              <div class="text-caption font-weight-medium">
                {{ item.VALIDFROM.slice(0, 10) }}
              </div>
            </template>
            <template #[`item.DOCNUM`]="{ item }">
              <v-chip class="text-caption font-weight-medium" color="chip" label small>
                {{ item.DOCNUM }}
              </v-chip>
            </template>
            <template #[`item.MATERIAL`]="{ item }">
              <v-chip class="text-caption font-weight-medium" color="chip" label small>
                {{ item.MATERIAL }}
              </v-chip>
            </template>

            <template #item.MTEXT="{ item }">
              <span class="text-caption font-weight-medium"> {{ item.MTEXT }} </span>
            </template>

            <template #item.QUANTITY="{ item }">
              <span class="text-caption font-weight-medium"> {{ item.QUANTITY }} </span>
            </template>

            <template #item.AVAILQTY="{ item }">
              <span class="font-weight-bold"> {{ item.AVAILQTY }} </span>
            </template>

            <template #[`item.SPRICE`]="{ item }">
              <div class="success--text text-caption font-weight-medium">
                {{ item.SPRICE | currency }}
              </div>
            </template>

            <template #[`item.GRANDTOTAL`]="{ item }">
              <div class="success--text text-caption font-weight-medium">
                {{ item.GRANDTOTAL | currency }}
              </div>
            </template>

            <template #[`item.adres`]="{ item }"> {{ item.ADDRESSLINE1 }} - {{ item.NAME1 }} </template>
          </v-data-table>
        </v-card>
      </div>
      <div v-else>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.smAndDown" />

        <v-alert v-if="userData.role === 'PLSYR' && !$store.state.plasiyerCustomer" tile type="warning">
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import {
  mdiClipboardList,
  mdiClose,
  mdiDownload,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiOrderBoolAscending,
  mdiOrderNumericAscending,
  mdiPlusThick,
  mdiPrinter,
  mdiRefresh,
  mdiSend,
} from '@mdi/js'
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import trackingStoreModule from '../trackingStoreModule'
import trackingFilter from './TrackingFilter.vue'

export default {
  components: {
    trackingFilter,
    VSnackbars,
    CustomerList,
  },
  setup() {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-tracking'
    const snackbars = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)
    const sendData = ref({})
    onMounted(() => {
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        loading.value = true
        fetchTrackings1()
      }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          loading.value = true
          fetchTrackings1()
        }
      },
    )

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, trackingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
      }
    })

    const isFilterNumber = ref(0)
    const isFilterTrackingSidebarActive = ref(false)
    const trackingListTable = ref([])

    const tableColumns = ref([
      {
        text: 'Tarih',
        value: 'VALIDFROM',
      },

      {
        text: 'Sipariş Numarası',
        value: 'DOCNUM',
      },
      {
        text: 'Malzeme Kodu',
        value: 'MATERIAL',
      },

      {
        text: 'Tanım',
        value: 'MTEXT',
      },
      {
        text: 'Sipariş Miktarı',
        value: 'QUANTITY',
      },
      {
        text: 'Açık Miktar',
        value: 'AVAILQTY',
      },
      {
        text: 'Birim Fiyat',
        value: 'SPRICE',
      },
      {
        text: 'Satır Toplamı',
        value: 'GRANDTOTAL',
      },
      {
        text: 'Adres',
        value: 'adres',
        sortable: false,
        width: '350px',
      },
    ])

    const searchQuery = ref('')
    const urunKodFilter = ref(null)
    const idFilter = ref(null)
    const siparisDurumFilter = ref(null)
    const odemeDurumFilter = ref(null)
    const startDateFilter = ref(null)
    const endDateFilter = ref(null)
    const minPriceFilter = ref(null)
    const maxPriceFilter = ref(null)

    const statusFilter = ref(null)
    const baslikFilter = ref(null)
    const customerFilter = ref(null)
    const totalTrackingListTable = ref(0)
    const customerGroupOptions = ref([])
    const pricelistOptions = ref([])
    const loading = ref(false)
    const dialogLogs = ref(false)
    const dialogStatus = ref(false)
    const disabledItems = ref(false)
    const disabledItemsPdf = ref(false)

    const dialogStatusData = ref({})
    const dialogStatusValue = ref(null)
    const dialogStatusNote = ref(null)
    const loadingItems = ref([])
    const loadingItemsPdf = ref([])
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const trackingTotalLocal = ref([])
    const selectedRows = ref([])
    const dialogLogsData = ref({
      id: null,
      response: null,
    })

    const json_data = ref([])
    const fetchTrackings1 = (status = false, id = null) => {
      store
        .dispatch('app-tracking/fetchTrackings1', {})
        .then(response => {
          if (response.error == 0) {
            console.log(response.detail)
            trackingListTable.value = response.detail
            response.detail.forEach(item => {
              item.DOCNUM = "'" + item.DOCNUM
            })
            json_data.value = response.detail
            //totalTrackingListTable.value = Number(response.response.pagination.totalRec)

            loading.value = false
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: 'Bir Hata Oluştu',
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    return {
      disabledItemsPdf,
      sendData,
      json_data,
      dialogLogsData,
      loadingItems,
      disabledItems,
      dialogLogs,
      dialogStatusData,
      dialogStatusValue,
      dialogStatusNote,
      dialogStatus,
      isFilterNumber,
      status,
      trackingListTable,
      tableColumns,
      searchQuery,
      urunKodFilter,
      baslikFilter,
      idFilter,
      statusFilter,
      totalTrackingListTable,
      loading,
      options,
      trackingTotalLocal,
      isFilterTrackingSidebarActive,
      selectedRows,
      fetchTrackings1,
      snackbars,
      customerFilter,
      customerGroupOptions,
      pricelistOptions,
      userData,
      myData: [],
      rows: [],

      // icons
      icons: {
        mdiEye,
        mdiClose,
        mdiClipboardList,
        mdiOrderNumericAscending,
        mdiFilter,
        mdiDownload,
        mdiSend,
        mdiFilterOff,
        mdiRefresh,
        mdiPlusThick,
        mdiOrderBoolAscending,
        mdiPrinter,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 140 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      json_fields: {
        Tarih: 'VALIDFROM',
        'Sipariş Numarası': 'DOCNUM',
        'Malzeme Kodu': 'MATERIAL',
        Tanım: 'MTEXT',
        'Sipariş Miktar': 'QUANTITY',
        'Açık Miktar': 'AVAILQTY',
        'Birim Fiyat': 'SPRICE',
        'Satır Toplamı': 'GRANDTOTAL',
      },
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
